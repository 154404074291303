import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import {
  Footer as FooterTheme,
  FooterType2,
  FooterType3,
} from '../../../../themes/nerdify/components/Footer/Footer';
//import { Footer as FooterTheme, FooterIcon } from '../../../../themes/nerdify/components/Footer/Footer';

import {
  REACT_APP_CONTACT_EMAIL,
  REACT_APP_ADDRESS_US,
  REACT_APP_ADDRESS_EU,
  REACT_APP_ADDRESS_UK,
  //REACT_APP_SITENAME
} from '../../../../config';

const FooterNavList = () => {
  return (
    <nav className="footer-nav">
      <NavLink className="footer-nav-link" to="/">
        Home
      </NavLink>{' '}
      {/*.break-after-desktop .break-after-mobile*/}
      <NavLink className="footer-nav-link" to="/become">
        Become a Nerd
      </NavLink>
      <NavLink className="footer-nav-link" to="/top-nerds">
        TOP Nerds
      </NavLink>
      <NavLink className="footer-nav-link" to="/nerdify-reviews">
        Nerdify Reviews
      </NavLink>
      <NavLink className="footer-nav-link" to="/how-it-works">
        How It Works
      </NavLink>
      <NavLink className="footer-nav-link" to="/for-academia">
        For Academia
      </NavLink>
      <NavLink className="footer-nav-link" to="/for-students">
        For Students
      </NavLink>
      <NavLink className="footer-nav-link" to="/for-businesses">
        For Businesses
      </NavLink>
      <NavLink className="footer-nav-link" to="/about">
        About Us
      </NavLink>
      <NavLink className="footer-nav-link" to="/terms-and-conditions">
        Terms of Use
      </NavLink>
      <NavLink className="footer-nav-link" to="/privacy-policy">
        Privacy Policy
      </NavLink>
      <NavLink className="footer-nav-link" to="/cookies">
        Cookie Policy
      </NavLink>
      <NavLink className="footer-nav-link" to="/refunds">
        Refund Policy
      </NavLink>
      <NavLink className="footer-nav-link" to="/honor-code">
        Honor Code
      </NavLink>
      <NavLink className="footer-nav-link" to="/report-abuse">
        Report Abuse
      </NavLink>
    </nav>
  );
};

export default (props) => {
  return (
    <FooterTheme {...props}>
      <FooterNavList />
    </FooterTheme>
  );
};

export const Footer2 = (props) => {
  return (
    <FooterType2 {...props}>
      <Fragment>
        <p className="footer-content1">
          {props.topText ?? "Nerdify. Made with love."}
        </p>
        <p className="footer-content2">
          By accessing this website you agree to our Terms of Use, Privacy and
          Cookie Policies.
        </p>

        <FooterNavList />

        <div className="bottom-part">
          <address className="footer-address">
            <div className="bottom-title">Our Addresses:</div>
            {REACT_APP_ADDRESS_US && (
              <div className="footer-address-line">
                <span className="footer-address-title">Our US address: </span>
                {REACT_APP_ADDRESS_US}
              </div>
            )}
            {REACT_APP_ADDRESS_EU && (
              <div className="footer-address-line">
                <span className="footer-address-title">Our EU address: </span>
                {REACT_APP_ADDRESS_EU}
              </div>
            )}
            {REACT_APP_ADDRESS_UK && (
              <div className="footer-address-line">
                <span className="footer-address-title">Our UK address: </span>
                {REACT_APP_ADDRESS_UK}
              </div>
            )}
          </address>
          <div className="footer-contacts">
            <div className="bottom-title">Contact Us:</div>
            <a
              className="footer-content-link"
              href={'mailto:' + REACT_APP_CONTACT_EMAIL}
            >
              {REACT_APP_CONTACT_EMAIL}
            </a>
          </div>
        </div>
      </Fragment>
    </FooterType2>
  );
};

export const FooterClp3 = (props) => {
  return (
    <FooterType3 {...props}>
      <Fragment>
        <p className="footer-content1">
          Nerdify. Made with love.
        </p>
        <p className="footer-content2">
          By accessing this website you agree to our Terms of Use, Privacy and
          Cookie Policies.
        </p>

        <FooterNavList />

        <address className="footer-address-block">
          <div className="bottom-title bottom-address-title">
            Our Addresses:
          </div>
          {REACT_APP_ADDRESS_US && (
            <div className="footer-address-line">
              <span className="footer-address-title">Our US address: </span>
              <span className="footer-address">{REACT_APP_ADDRESS_US}</span>
            </div>
          )}
          <div className="footer-address-line">
            <span className="footer-address-title">Our EU address: </span>
            <span className="footer-address">
              Tilemachou Kanthou 6, 1047 Nicosia, Cyprus
            </span>
          </div>
        </address>
        <div className="footer-contacts">
          <div className="bottom-title">Contact Us:</div>
          <a
            className="footer-contact-link"
            href={'mailto:' + REACT_APP_CONTACT_EMAIL}
          >
            {REACT_APP_CONTACT_EMAIL}
          </a>
        </div>
      </Fragment>
    </FooterType3>
  );
};
