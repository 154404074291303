
import React, {
  Fragment
} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Facebook1Theme, Facebook4Theme, FacebookButton, FacebookButton1 } from '../../../../themes/nerdify/components/Facebook'
import { REACT_APP_FACEBOOK_PAGE } from "../../../../config";

import { useMobile } from '../../../../hooks';

const FacebookContent = ({ isAu }) => {
  return (
    <Fragment>
      <h5 className='h5 fb-title'>Don’t want to leave your<br className="mobile-only" /> phone number?</h5>
      <p className='p fb-subtitle'>Message us via Facebook Messenger</p>
      <FacebookButton
        facebookPage={isAu && 'http://m.me/gonerdify.au' || REACT_APP_FACEBOOK_PAGE}
      >Message Us</FacebookButton>
    </Fragment>
  )
}

const FacebookContent2 = () => {
  const isMobile = useMobile();

  const locales = useSelector(state => state.locales);
  const { data } = locales;

  return (
    <Fragment>
      <div className="facebook__title">
        <div className="facebook__title-text">{!isMobile && <Fragment>…</Fragment>}or contact us via FB Messenger</div>
      </div>
      <FacebookButton1 facebookPage={data.fbLink}>Message Us</FacebookButton1>
    </Fragment>
  )
}

export const Facebook1 = ({ isAu }) => (
  <Facebook1Theme>
    <FacebookContent isAu={isAu} />
  </Facebook1Theme>
);

export const Facebook2 = (props) => (
  <Facebook4Theme {...props}>
    <FacebookContent2 />
  </Facebook4Theme>
);
